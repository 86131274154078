import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/core";
import Logo from "~/components/Logo";
import { useTheme } from "emotion-theming";
import useMedia from "~/hooks/useMedia";
import useLockBodyScroll from "~/hooks/useLockBodyScroll";
import Img from "gatsby-image";
import goennDir from "~/images/goenndir.jpg";
import leger from "~/images/leger.jpg";
import wild from "~/images/wild.jpg";

import emailJs from "emailjs-com";

const EMAILJS_TEMPLATE_ID = "template_jiyo608";

const PACKAGES = {
  LEGERE: {
    title: "Légère",
    picture: leger,
    small: {
      price: "CHF 189.-",
      itemGroups: [
        {
          title: "Apéro",
          items: [
            "1 Flasche Glühwein",
            "6 Flaschen Bier",
            "1 Flasche Prosecco oder Weisswein",
          ],
        },
        {
          title: "Gin Tonic",
          items: [
            "1 Flasche Turicum Handcrafted Gin",
            "8 Flaschen Fever Tree Tonic 20cl",
            "2 Zitronen",
          ],
        },
      ],
    },
    big: {
      price: "CHF 349.-",
      itemGroups: [
        {
          title: "Apéro",
          items: [
            "2 Flaschen Glühwein",
            "12 Flaschen Bier",
            "2 Flaschen Prosecco oder Weisswein",
          ],
        },
        {
          title: "Gin Tonic",
          items: [
            "2 Flaschen Turicum Handcrafted Gin",
            "16 Flaschen Fever Tree Tonic 20cl",
            "4 Zitronen",
          ],
        },
      ],
    },
  },
  WILD: {
    title: "Wild",
    picture: wild,
    small: {
      price: "CHF 229.-",
      itemGroups: [
        {
          title: "Apéro",
          items: [
            "1 Flasche Glühwein",
            "6 Flaschen Bier",
            "1 Flasche Prosecco oder Weisswein",
          ],
        },
        {
          title: "Gin Tonic",
          items: [
            "1 Flasche Turicum Handcrafted Gin",
            "8 Flaschen Fever Tree Tonic 20cl",
            "2 Zitronen",
          ],
        },
        {
          title: "Shots",
          items: ["1 Flasche Hierbas"],
        },
      ],
    },
    big: {
      price: "CHF 439.-",
      itemGroups: [
        {
          title: "Apéro",
          items: [
            "2 Flaschen Glühwein",
            "12 Flaschen Bier",
            "2 Flaschen Prosecco oder Weisswein",
          ],
        },
        {
          title: "Gin Tonic",
          items: [
            "2 Flaschen Turicum Handcrafted Gin",
            "16 Flaschen Fever Tree Tonic 20cl",
            "4 Zitronen",
          ],
        },
        {
          title: "Shots",
          items: ["1 Flasche Hierbas", "1 Flasche Berliner Luft"],
        },
      ],
    },
  },
  LUXUS: {
    title: "Gönndir",
    picture: goennDir,
    small: {
      price: "CHF 499.-",
      itemGroups: [
        {
          title: "Apéro",
          items: ["1 Flasche Dom Pérignon 75cl"],
        },
        {
          title: "Gin Tonic",
          items: [
            "1 Flasche Gin Mare",
            "8 Flaschen Mediterranean Tonic 20cl",
            "2 Zitronen",
            "1 Strauch Rosmarin",
          ],
        },
        {
          title: "Shots",
          items: [
            "1 Flasche Belvedere Vodka",
            "1 Flasche Cranberry-Saft",
            "1 Flasche Pfirsichschnaps",
          ],
        },
      ],
    },
    big: {
      price: "CHF 899.-",
      itemGroups: [
        {
          title: "Apéro",
          items: ["1 Flasche Dom Pérignon 1.5l"],
        },
        {
          title: "Gin Tonic",
          items: [
            "2 Flaschen Gin Mare",
            "16 Flaschen Mediterranean Tonic 20cl",
            "4 Zitronen",
            "2 Sträuche Rosmarin",
          ],
        },
        {
          title: "Shots",
          items: [
            "1 Flasche Belvedere Vodka",
            "1 Flasche Cranberry-Saft",
            "1 Flasche Pfirsichschnaps",
            "1 Flasche Berliner Luft",
            "1 Flasche Hierbas",
          ],
        },
      ],
    },
  },
};



const glow = keyframes`
0% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
9% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
10% {
  text-shadow: 0 0 0 0;
  opacity: 0.5;
}
13% {
  text-shadow: 0 0 0 0;
  opacity: 0.5;
}
14% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
29% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
30% {
  text-shadow: 0 0 0 0;
  opacity: 0.5;
}
33% {
  text-shadow: 0 0 0 0;
  opacity: 0.5;
}
34% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
65% {
  text-shadow: 0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82;
  opacity: 1;
}
66% {
  text-shadow: 0 0 0 0;
  opacity: 0;
}
100% {
  text-shadow: 0 0 0 0;
  opacity: 0;
}
`;

const Section = styled.div(({ theme }) => ({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  position: "sticky",
  top: 0,
  backgroundColor: theme.colors.primary.green,
  overflow: "hidden",
}));

const Text = styled.div(({ theme }) => ({
  color: theme.colors.background,
  fontSize: "5rem",
}));

const SectionText = styled.div(({ theme }) => ({
  transform: "rotate(-90deg) translateX(-100%)",
  transformOrigin: "0 0",
  fontSize: "1.5rem",
  WebkitTextStrokeWidth: 0.25,
  WebkitTextStrokeColor: theme.colors.background,
  color: theme.colors.primary.green,
}));

const Title = styled.div(({ theme }) => ({
  fontSize: "12vw",
  WebkitTextStrokeWidth: 2,
  WebkitTextStrokeColor: theme.colors.background,
  color: "transparent",
  textShadow:
    "0 0 10px #fff, 0 0 20px #828f82, 0 0 30px #828f82, 0 0 40px #828f82, 0 0 50px #828f82, 0 0 60px #828f82, 0 0 70px #828f82",
  [theme.breakpoints.down("sm")]: {
    WebkitTextStrokeWidth: 1,
  },
}));

const AnimatedTitle = styled(Title)(({ theme }) => ({
  animation: `${glow} 4s linear 1s 1 forwards`,
}));

const SendButton = styled.div(({ theme }) => ({
  fontSize: "2rem",
  WebkitTextStrokeWidth: 1,
  WebkitTextStrokeColor: theme.colors.primary.green,
  color: theme.colors.background,
  cursor: "pointer",
  "&:hover": {
    color: theme.colors.primary.green,
  },
}));

const scroll = keyframes`
  from {
    bottom: 32px;
  }
  to {
    bottom: 40px;
  }

`;

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const ScrollDown = styled.div(({ theme }) => ({
  position: "absolute",
  bottom: 60,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  fontSize: "1.5rem",
  opacity: 0,
  textShadow:
    "0 0 2px #fff, 0 0 4px #828f82, 0 0 6px #828f82, 0 0 8px #828f82, 0 0 10px #828f82, 0 0 12px #828f82, 0 0 14px #828f82",
  animation: `${appear} 0.5s linear 8s 1 forwards, ${scroll} 1s linear 0s infinite alternate`,
}));

const Content = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
}));

const List = styled.div(({ theme }) => ({
  display: "flex",
  fontSize: "1.5rem",
  alignItems: "flex-start",
  flexDirection: "column",
}));

const ImgContainer = styled.div(({ theme }) => ({
  display: "flex",
  flex: 1,
  position: "relative",
}));

const Input = styled.input(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.fonts.futura,
  fontSize: "1.5rem",
  borderBottomColor: theme.colors.primary.olive,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderTopColor: theme.colors.primary.olive,
  borderTopWidth: 0,
  borderTopStyle: "solid",
  borderLeftColor: theme.colors.primary.olive,
  borderLeftWidth: 0,
  borderLeftStyle: "solid",
  borderRightColor: theme.colors.primary.olive,
  borderRightWidth: 0,
  borderRightStyle: "solid",
  borderRadius: 0,
  color: theme.colors.primary.green,
  "&:focus": {
    borderBottomColor: theme.colors.primary.green,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderTopColor: theme.colors.primary.olive,
    borderTopWidth: 0,
    borderTopStyle: "solid",
    borderLeftColor: theme.colors.primary.olive,
    borderLeftWidth: 0,
    borderLeftStyle: "solid",
    borderRightColor: theme.colors.primary.olive,
    borderRightWidth: 0,
    borderRightStyle: "solid",
    outline: "none",
  },
}));

const TextArea = styled.textarea(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.fonts.futura,
  fontSize: "1.5rem",
  borderBottomColor: theme.colors.primary.olive,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderTopColor: theme.colors.primary.olive,
  borderTopWidth: 0,
  borderTopStyle: "solid",
  borderLeftColor: theme.colors.primary.olive,
  borderLeftWidth: 0,
  borderLeftStyle: "solid",
  borderRightColor: theme.colors.primary.olive,
  borderRightWidth: 0,
  borderRightStyle: "solid",
  borderRadius: 0,
  color: theme.colors.primary.green,
  resize: "none",
  "&:focus": {
    borderBottomColor: theme.colors.primary.green,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderTopColor: theme.colors.primary.olive,
    borderTopWidth: 0,
    borderTopStyle: "solid",
    borderLeftColor: theme.colors.primary.olive,
    borderLeftWidth: 0,
    borderLeftStyle: "solid",
    borderRightColor: theme.colors.primary.olive,
    borderRightWidth: 0,
    borderRightStyle: "solid",
    outline: "none",
  },
}));

const InnerContainer = styled.div(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flex: 1,
  alignSelf: "stretch",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

function PackageTitle({ url, title }) {
  const theme = useTheme();
  return (
    <ImgContainer
      css={{
        position: "relative",
        [theme.breakpoints.down("md")]: {
          flexGrow: 0,
          flexBasis: 300,
        },
        [theme.breakpoints.down("sm")]: {
          flexGrow: 0,
          flexBasis: 250,
        },
      }}
    >
      <img
        alt={title}
        src={url}
        css={{
          width: "50vw",
          height: "100vh",
          objectFit: "cover",
          [theme.breakpoints.down("md")]: {
            height: 300,
            width: "100vw",
            objectFit: "cover",
          },
          [theme.breakpoints.down("sm")]: {
            height: 250,
            width: "100vw",
            objectFit: "cover",
          },
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: theme.colors.primary.olive,
            width: 300,
            height: 30,
          }}
        ></div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: "6rem", textTransform: "uppercase" }}>
          {title}
        </Text>
      </div>
    </ImgContainer>
  );
}

function PackagePage({ personCount, setPersonCount, box, index }) {
  const theme = useTheme();

  const isSmall = useMedia(`(max-width: ${theme.breakpoints.md}px)`);

  const isEven = index % 2 === 0 || isSmall;

  const { title, small, big, picture } = box;
  const selectedBox = personCount === 4 ? small : big;

  const { price, itemGroups } = selectedBox;

  return (
    <Section
      css={{
        flex: "1 0 auto !important",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      }}
    >
      {isEven && <PackageTitle url={picture} title={title} />}
      <Content style={{ flexDirection: "column" }}>
        <div style={{ padding: 32 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <div
              onClick={() => setPersonCount(4)}
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                color: personCount === 4 ? theme.colors.background : "inherit",
              }}
            >
              4 Personen
            </div>
            <div
              style={{
                marginTop: 6,
                marginLeft: 12,
                marginRight: 12,
                width: 60,
                height: 2,
                backgroundColor: theme.colors.primary.olive,
              }}
            />
            <div
              onClick={() => setPersonCount(8)}
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                color: personCount === 8 ? theme.colors.background : "inherit",
              }}
            >
              8 Personen
            </div>
          </div>

          {itemGroups.map((itemGroup) => {
            const { title: groupTitle, items } = itemGroup;
            return (
              <div
                key={groupTitle}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  marginTop: 40,
                }}
              >
                <SectionText style={{ position: "absolute", top: 0, left: 0 }}>
                  {groupTitle}
                </SectionText>
                <List
                  style={{
                    paddingLeft: 80,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {items.map((item) => (
                    <div key={item} style={{ color: theme.colors.background }}>
                      {item}
                    </div>
                  ))}
                </List>
              </div>
            );
          })}
          <div
            style={{
              marginTop: 60,
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "2rem",
            }}
          >
            {price}
          </div>
        </div>
      </Content>
      {!isEven && <PackageTitle url={picture} title={title} />}
    </Section>
  );
}

function IndexPage() {
  const theme = useTheme();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [request, setRequest] = React.useState("");
  const [personCount, setPersonCount] = React.useState(4);
  const [emailSent, setEmailSent] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [lightOff, setLightOff] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  useLockBodyScroll(openModal)

  const [formErrors, setFormErrors] = React.useState({
    mail: null,
    name: null,
  });

  async function handleSend() {
    let invalid = false;

    if (!name || name === "") {
      invalid = true;
      setFormErrors((prevError) => ({ ...prevError, name: "Wird benötigt" }));
    }
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))){
      invalid = true;
      setFormErrors((prevError) => ({
        ...prevError,
        email: "Gültige Email wird benötigt",
      }));
    }
    if (invalid) {
      return;
    }

    try {
      await emailJs.send(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        { name, email, request },
        process.env.GATSBY_EMAILJS_USER_ID
      );
      setName("");
      setEmail("");
      setRequest("");
    } catch (e) {
      setError(e);
    } finally {
      setEmailSent(true);
    }
  }

  function renderPackagePages() {
    return Object.values(PACKAGES).map((box, index) => (
      <PackagePage
        box={box}
        key={box.title}
        index={index}
        personCount={personCount}
        setPersonCount={setPersonCount}
      />
    ));
  }

  function renderFrontPage() {
    return (
      <Section>
        <InnerContainer>
          {!lightOff && (
            <AnimatedTitle onAnimationEnd={() => setLightOff(true)}>
              SARS-CoV-2
            </AnimatedTitle>
          )}
          {lightOff && <Title>XMAS-DinR-20</Title>}
          <Text
            css={{
              opacity: lightOff ? 1 : 0,
              transform: `translateY(${lightOff ? 0 : 40}px)`,
              transition: "all 0.3s ease-out 0.5s",
              fontSize: "2rem",
              textAlign: "center",
              marginTop: 24,
              color: theme.colors.primary.olive,
            }}
          >
            De Samichlaus isch nüme rot, sondern grüen, er bringt kei Gschänkli
            meh sondern Gin.<br/>Scho unterwegs.
            <br /><br/>
            <span
              css={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setOpenModal(true)}
            >
              Meh infos
            </span>
          </Text>
        </InnerContainer>
        <ScrollDown>Scrolle</ScrollDown>
      </Section>
    );
  }

  function renderInputs() {
    return (
      <>
        <div style={{ alignSelf: "stretch" }}>
          <label
            style={{
              marginTop: 40,
              fontSize: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Name
            <Input
              type="text"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            {formErrors.name && (
              <div
                style={{
                  marginTop: 4,
                  fontSize: "1rem",
                  color: theme.colors.select,
                }}
              >
                {formErrors.name}
              </div>
            )}
          </label>

          <label
            style={{
              marginTop: 40,
              fontSize: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Email
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            {formErrors.email && (
              <div
                style={{
                  marginTop: 4,
                  fontSize: "1rem",
                  color: theme.colors.select,
                }}
              >
                {formErrors.email}
              </div>
            )}
          </label>

          <label
            style={{
              marginTop: 40,
              fontSize: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Wunsch / Anfrage
            <TextArea
              type="text"
              name="request"
              rows="3"
              value={request}
              onChange={(event) => setRequest(event.target.value)}
            />
          </label>
        </div>

        <SendButton
          onClick={handleSend}
          onKeyDown={handleSend}
          style={{ marginTop: 32, letterSpacing: "0.2rem", display:"inline-flex" }}
          type="button"
        >
          ABSENDEN
        </SendButton>
      </>
    );
  }

  function renderThankYou() {
    return (
      <div
        style={{
          marginTop: 40,
          fontSize: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        Die Anfrage wurde erfolgreich versendet. Wir melden uns möglichst bald.
      </div>
    );
  }

  function renderContactPage() {
    return (
      <Section
        style={{
          backgroundColor: theme.colors.background,
        }}
      >
        <InnerContainer>
          <div style={{ maxWidth: 1000, width: "100%" }}>
            <Text css={{ color: theme.colors.primary.green, fontSize: "3rem" }}>
              {emailSent ? "Danke" : "Kontakt aufnehmen"}
            </Text>

            {!emailSent && (
              <Text
                css={{
                  paddingTop: theme.spacing(1),
                  color: theme.colors.primary.green,
                  fontSize: "1.5rem",
                }}
              >
                Die Bestandteile der Pakete dienen als Vorschlag und können
                gerne nach Euren Vorlieben ausgetauscht werden.
              </Text>
            )}

            {emailSent ? renderThankYou() : renderInputs()}

            <div
              css={{
                display: "flex",
                flexDirection: "row",
                marginTop: theme.spacing(4),
                marginLeft: theme.spacing(-3),
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Text
                onClick={() => window.open("https://nahschub.ch/")}
                css={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: theme.colors.primary.olive,
                  marginLeft: theme.spacing(3),
                  marginTop: theme.spacing(2),
                  fontSize: "1.5rem",
                }}
              >
                nahschub.ch
              </Text>
              <Text
                onClick={() =>
                  window.open("https://www.instagram.com/nahschub/")
                }
                css={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: theme.colors.primary.olive,
                  marginLeft: theme.spacing(3),
                  marginTop: theme.spacing(2),
                  fontSize: "1.5rem",
                }}
              >
                Instagram
              </Text>
              <Text
                css={{
                  color: theme.colors.primary.olive,
                  marginLeft: theme.spacing(3),
                  marginTop: theme.spacing(2),
                  fontSize: "1.5rem",
                }}
              >
                bar@nahschub.ch
              </Text>
              <Text
                css={{
                  color: theme.colors.primary.olive,
                  marginLeft: theme.spacing(3),
                  marginTop: theme.spacing(2),
                  fontSize: "1.5rem",
                }}
              >
                076 421 95 99
              </Text>
            </div>
          </div>
        </InnerContainer>
      </Section>
    );
  }

  return (
    <div>
      {renderFrontPage()}
      {renderPackagePages()}
      {renderContactPage()}
      <div
        css={{
          position: "fixed",
          flex: 1,
          left: 0,
          right: 0,
          top: 32,
          zIndex: 3000,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mixBlendMode: "difference",
        }}
      >
        <div
          css={{ cursor: "pointer" }}
          onClick={() => window.open("https://nahschub.ch")}
        >
          <Logo variant="filled" width="12vw" minWidth={120} />
        </div>
      </div>
      <div
        css={{
          pointerEvents: openModal ? "all" : "none",
          opacity: openModal ? 1 : 0,
          transition: "all 0.5s ease-out",
          position: "fixed",
          top: 32,
          right: 32,
          zIndex: 4000,
          cursor: "pointer",
        }}
      >
        <SendButton onClick={() => setOpenModal(false)}>X</SendButton>
      </div>
      <div
        css={{
          pointerEvents: openModal ? "auto" : "none",
          opacity: openModal ? 1 : 0,
          transition: "all 0.5s ease-out",
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.colors.background,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 32,
          flexDirection:"column",
        }}
      >
        <Text css={{ color: theme.colors.primary.green, fontSize:"1.5rem", maxWidth: 1000, }}>
          Liebe Freund*innen des Festes<br/><br/>Was wäre ein Firmenfest ohne die roten
          Köpfe, die Blicke am nächsten Tag im Büro und die Tuscheleien darüber
          was am Vorabend wohl alles passiert sein könnte. Da wir alle in diesem
          Jahr Schwierigkeiten haben, unsere heissgeliebte Firmenweihnachtsfeste
          zu feiern, hat nahschub es sich zur ehrenvollen Aufgabe gemacht, euch
          doch noch Freude ins Büro zu bringen. Über nahschub könnt ihr euren
          Anlass doch noch retten: Wir liefern euch die richtigen Getränke dazu,
          zum Beispiel: Apéro nach Wahl zur Vorspeise, Gin als Hauptgang und die
          Shots als Dessert – natürlich kühl und mit Eis.<br/><br/>Wir haben uns drei
          Pakete ausgedacht mit denen ihr es ordentlich krachen lassen könnt:<br/>
          Einmal eines für die Strebis unter euch, eines für die “Raudis und
          Pausenclowns” und einmal ein Paket für welche, bei denen das (Corona)Jahr
          außerordentlich gut gelaufen ist – oder es einfach wirklich nicht mehr
          aushalten. Natürlich hoffen wir für euch alle auf Ersteres. Falls Gin Tonic nicht euer all time favourit ist, können wir auch
          gerne nach Absprache einen fliegenden Wechsel vornehmen.<br/><br/>Viel
          Liebe, Gesundheit und ein gutes Restjahr wünscht euch nahschub<br/><br/>
          
        </Text>
      </div>
    </div>
  );
}

export default IndexPage;
